import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";

const Leaderboard = ({ leaderboard, handleViewLeaderboard }) => {
  // Sort leaderboard dynamically based on progress
  const sortedLeaderboard = leaderboard.sort((a, b) => b.progress - a.progress);

  return (
    <Card
      className="p-shadow-5"
      style={{
        backgroundColor: "#111",
        color: "#FFF",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <h3>Leaderboard</h3>
      <Divider />
      {sortedLeaderboard.length > 0 ? (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {sortedLeaderboard.map((player, index) => (
            <li
              key={player.username}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 0",
                borderBottom: "1px solid #333",
              }}
            >
              <span>#{index + 1}</span>
              <span>
                <a
                  href={`https://x.com/${player.username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#1DA1F2",
                    textDecoration: "none",
                  }}
                >
                  @{player.username}
                </a>
              </span>
              <span>{player.progress}%</span>
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ color: "#AAA" }}>Leaderboard data not available.</p>
      )}
      <Button
        label="View Full Leaderboard"
        icon="pi pi-chart-bar"
        className="p-button-outlined"
        style={{
          width: "100%",
          padding: "12px",
          color: "#FFF",
          fontWeight: "bold",
          borderRadius: "8px",
          border: "1px solid rgba(255,255,255,0.4)",
          marginTop: "16px",
        }}
        onClick={handleViewLeaderboard}
      />
    </Card>
  );
};

export default Leaderboard;
