import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";

function Leaderboard() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/leaderboard`);
        setLeaderboard(response.data || []);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [backendUrl]);

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000",
          color: "#FFF",
        }}
      >
        <Skeleton
          width="80%"
          height="200px"
          borderRadius="12px"
          style={{ backgroundColor: "#333" }}
        />
      </div>
    );
  }

  return (
    <Layout>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#000",
          color: "#FFF",
          padding: "16px",
        }}
      >
        <Card
          className="p-shadow-5"
          style={{
            width: "95%",
            maxWidth: "700px",
            margin: "0 auto",
            backgroundColor: "#111",
            color: "#FFF",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "12px",
              textAlign: "center",
            }}
          >
            Leaderboard
          </h2>
          {leaderboard.length > 0 ? (
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                margin: 0,
                backgroundColor: "#222",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              {leaderboard.map((player, index) => (
                <li
                  key={player.username}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 12px", // Reduced padding
                    borderBottom:
                      index !== leaderboard.length - 1 ? "1px solid #333" : "none",
                    color: "#FFF",
                    fontSize: "14px", // Reduced font size
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "8px",
                      flexShrink: 0,
                    }}
                  >
                    {index + 1}.
                  </span>
                  <span
                    style={{
                      flex: 1,
                      textAlign: "left",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <a
                      href={`https://x.com/${player.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#1DA1F2",
                        textDecoration: "none",
                      }}
                    >
                      @{player.username}
                    </a>
                  </span>
                  <span
                    style={{
                      flexShrink: 0,
                      textAlign: "right",
                    }}
                  >
                    {player.progress}%
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p style={{ color: "#AAA", textAlign: "center" }}>
              No leaderboard data available.
            </p>
          )}
        </Card>
      </div>
    </Layout>
  );
}

export default Leaderboard;
