import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Divider } from "primereact/divider";
import "../index.css"; // Custom spinner styling is applied

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Login() {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading state to block card rendering
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const loginMessage =
    process.env.REACT_APP_DESCRIPTION || "Sign in to continue";

  // Handle authentication check and redirection
  useEffect(() => {
    if (user) {
      setLoading(true); // Show the progress indicator when user is authenticated
      setTimeout(() => {
        navigate("/dashboard"); // Redirect to the dashboard
      }, 1000); // Optional delay for a better UX
    } else {
      setLoading(false); // Stop loading if the user is not authenticated
    }
  }, [user, navigate]);

  if (loading) {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(135deg, #000000 0%, #1A1A1A 100%)",
          color: "#FFF",
        }}
      >
        <ProgressSpinner
          className="custom-spinner"
          style={{ width: "50px", height: "50px" }}
          strokeWidth="4"
          fill="#111"
        />
      </div>
    );
  }

  return (
    <div
      className="p-d-flex p-jc-center p-ai-center"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(135deg, #000000 0%, #1A1A1A 100%)",
        color: "#FFF",
        margin: 0,
        padding: "0 16px", // Add padding for better spacing on small screens
      }}
    >
      <Card
        className="p-shadow-5"
        style={{
          maxWidth: "400px",
          width: "100%",
          backgroundColor: "#111",
          color: "#FFF",
          border: "1px solid rgba(255,255,255,0.4)",
          borderRadius: "8px",
          textAlign: "center",
          padding: "24px",
        }}
      >
        <h2 style={{ marginBottom: "16px" }}>
          Welcome to {process.env.REACT_APP_APP_NAME}
        </h2>
        <p style={{ color: "#AAA", marginBottom: "24px" }}>{loginMessage}</p>
        <Button
          label="Sign In With X"
          icon="pi pi-twitter"
          style={{
            width: "100%", // Full width for better alignment
            maxWidth: "300px", // Limit button width on larger screens
            padding: "12px 16px",
            backgroundColor: hover ? "#1DA1F2" : "#FFF",
            color: hover ? "#FFF" : "#000",
            border: "none",
            borderRadius: "8px",
            fontWeight: "bold",
          }}
          onClick={() => (window.location.href = `${backendUrl}/auth/twitter`)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />

        <Divider />
        
        <p style={{ marginTop: "24px", color: "#AAA", fontSize: "12px" }}>
          By signing in, you agree to our{" "}
          <a href="/terms">terms and conditions</a> and{" "}
          <a href="/privacy">privacy policy</a>.
        </p>
      </Card>
    </div>
  );
}

export default Login;
