import React from "react";
import Layout from "./Layout";

function TermsAndConditions() {
  return (
    <Layout>
      <div style={{ color: "#FFF", padding: "16px", fontSize: "0.9rem", lineHeight: "1.6" }}>
        <h1 style={{ fontSize: "1.8rem", marginBottom: "16px" }}>Terms and Conditions</h1>
        <p>
          Welcome to <strong>C1pher.Quest</strong>. By accessing or using our platform, you agree to comply with these Terms and Conditions. Please read them carefully.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>1. Introduction</h2>
        <p>
          C1pher.Quest is an immersive puzzle-solving and intellectual challenge platform designed to spark creativity and curiosity. The terms "we," "our," or "us" refer to C1pher.Quest, and "you" or "your" refers to the user or visitor of the platform.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>2. Eligibility</h2>
        <p>
          - You must be at least 13 years of age to use the platform. Users under 18 require permission from a parent or legal guardian.
          <br />
          - By registering, you confirm that the information provided is accurate and truthful.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>3. Usage Rights</h2>
        <p>
          - C1pher.Quest grants you a limited, non-transferable, and revocable license to use the platform for personal, non-commercial purposes.
          <br />
          - You are responsible for maintaining the confidentiality of your account credentials and activities.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>4. Intellectual Property</h2>
        <p>
          - All content on C1pher.Quest, including puzzles, designs, and other materials, are owned by us and protected by intellectual property laws.
          <br />
          - You are prohibited from reproducing, distributing, or modifying any content without prior written consent.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>5. Prohibited Conduct</h2>
        <p>
          - You agree not to engage in activities that could harm the platform, its users, or its content. This includes:
          <ul style={{ marginLeft: "16px", listStyleType: "circle" }}>
            <li>Attempting to hack or disrupt the platform.</li>
            <li>Posting or transmitting harmful, defamatory, or illegal material.</li>
            <li>Exploiting the platform for commercial gain without authorization.</li>
          </ul>
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>6. Limitation of Liability</h2>
        <p>
          - We provide our services on an "as-is" and "as-available" basis. While we strive for accuracy, we do not guarantee the completeness or reliability of the platform's content.
          <br />
          - C1pher.Quest is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>7. Termination</h2>
        <p>
          - We reserve the right to suspend or terminate your account for any breach of these terms.
          <br />
          - Upon termination, your access to the platform and its content will be revoked.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>8. Updates to Terms</h2>
        <p>
          - We may revise these Terms and Conditions from time to time. Continued use of the platform after changes are made constitutes your acceptance of the updated terms.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>9. Governing Law</h2>
        <p>
          - These terms are governed by and construed in accordance with the laws of your jurisdiction. Any disputes shall be resolved in the appropriate courts of your location.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>10. Contact Us</h2>
        <p>
          If you have questions or concerns about these Terms and Conditions, please contact us at:
          <br />
          <a href="mailto:support@c1pher.quest" style={{ color: "#00ADEF" }}>
            support@c1pher.quest
          </a>
        </p>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;
