import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

const Attempts = ({ attempts }) => {
  return (
    <Card
      className="p-shadow-5"
      style={{
        backgroundColor: "#111",
        color: "#FFF",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <h3>Your Attempts</h3>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {attempts.length > 0 ? (
          attempts.map((attempt) => (
            <div
              key={attempt.riddleNumber}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
                gap: "8px",
                textAlign: "left",
                borderBottom: "1px solid #333",
                padding: "8px 0",
              }}
            >
              <span>
                <strong>Challenge:</strong> {attempt.riddleNumber}
              </span>
              <span>
                <strong>Attempts:</strong> {attempt.attempts}
              </span>
              <span>
                <strong>Time:</strong> {Math.floor(attempt.timeSpent / 60)}m{" "}
                {Math.round(attempt.timeSpent % 60)}s
              </span>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", padding: "16px 0" }}>
            No attempts yet.
          </div>
        )}
      </div>
    </Card>
  );
};

export default Attempts;
