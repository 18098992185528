import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { FaShareAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa"; // Import Profile Icon

const Progress = ({ progress, publicLink, handleShare, handleQuest, handleProfile }) => {
  return (
    <Card
      className="p-shadow-5"
      style={{
        backgroundColor: "#111",
        color: "#FFF",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>Your Progress</h3>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            icon={<FaShareAlt />}
            className="p-button-rounded p-button-outlined"
            style={{
              width: "36px", // Reduced size
              height: "36px",
              padding: "0",
              border: "1px solid rgba(255,255,255,0.4)",
              color: "#FFF",
            }}
            tooltip="Share"
            tooltipOptions={{ position: "bottom" }}
            onClick={handleShare}
          />
          <Button
            icon={<FaUserAlt />}
            className="p-button-rounded p-button-outlined"
            style={{
              width: "36px", // Reduced size
              height: "36px",
              padding: "0",
              border: "1px solid rgba(255,255,255,0.4)",
              color: "#FFF",
            }}
            tooltip="Profile"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => handleProfile(publicLink)}
          />
        </div>
      </div>
      <Divider />
      <p style={{ color: "#AAA", marginBottom: "16px" }}>
        <strong>{progress || 0}%</strong> of the challenges completed.
      </p>
      {progress === 100 ? (
        <p style={{ color: "#4CAF50", fontWeight: "bold" }}>
          Congratulations! You've completed all challenges! 🎉
        </p>
      ) : (
        <Button
          label={progress === 0 ? "Start CipherQuest" : "Continue CipherQuest"}
          icon="pi pi-play"
          style={{
            backgroundColor: "#1DA1F2",
            color: "#FFF",
            fontWeight: "bold",
            borderRadius: "8px",
            padding: "12px 16px",
          }}
          onClick={handleQuest}
        />
      )}
    </Card>
  );
};

export default Progress;
