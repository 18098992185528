import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Skeleton } from "primereact/skeleton";

function PublicProfile() {
  const { publicLink } = useParams(); // Get publicLink from the route
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/profile/${publicLink}`
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching public profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [publicLink]);

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000",
          color: "#FFF",
        }}
      >
        <Skeleton
          width="80%"
          height="200px"
          borderRadius="12px"
          style={{ backgroundColor: "#333" }}
        />
      </div>
    );
  }

  if (!profile) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000",
          color: "#FFF",
        }}
      >
        <p style={{ color: "#AAA", fontSize: "18px" }}>
          Unable to load the profile. Please try again later.
        </p>
      </div>
    );
  }

  // Calculate total time spent
  const totalTimeSpent = profile.attempts.reduce(
    (total, attempt) => total + attempt.timeSpent,
    0
  );

  return (
    <Layout>
      <Helmet>
        {/* Meta Tags */}
        <title>{`@${profile.username}'s Secrets | C1pher.Quest`}</title>
        <meta name="description" content={`Discover the hidden journey of @${profile.username}.`} />
        <meta property="og:title" content={`Explore the Mysteries of @${profile.username}`} />
        <meta
          property="og:description"
          content={`@${profile.username} has uncovered ${profile.progress}% of the mysteries! Can you do better?`}
        />
        <meta property="og:image" content={`${process.env.REACT_APP_FRONTEND_URL}/mystery-og-image.png`} />
        <meta property="og:url" content={`${process.env.REACT_APP_FRONTEND_URL}/profile/${publicLink}`} />
        <meta property="og:type" content="profile" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`@${profile.username}'s Secret Profile`} />
        <meta
          name="twitter:description"
          content={`Explore @${profile.username}'s journey through C1pher.Quest. Join the challenge.`}
        />
        <meta name="twitter:image" content={`${process.env.REACT_APP_FRONTEND_URL}/mystery-og-image.png`} />
      </Helmet>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#000",
          color: "#FFF",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Progress Card */}
        <Card
          className="p-shadow-5"
          style={{
            maxWidth: "600px",
            width: "100%",
            backgroundColor: "#111",
            color: "#FFF",
            borderRadius: "12px",
            padding: "24px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "16px",
            }}
          >
            @{profile.username}'s Secrets
          </h2>
          <ProgressBar
            value={profile.progress}
            style={{
              marginBottom: "16px",
              height: "14px",
              borderRadius: "8px",
              backgroundColor: "#333",
            }}
            showValue={false}
          />
          <p style={{ fontSize: "18px", color: "#AAA" }}>
            {profile.progress}% of mysteries uncovered.
          </p>
        </Card>

        {/* Attempts Summary */}
        <div
          style={{
            maxWidth: "600px",
            width: "100%",
            marginTop: "32px",
          }}
        >
          <h3 style={{ color: "#FFF", marginBottom: "16px" }}>Exploration Log</h3>
          {profile.attempts.length > 0 ? (
            <>
              <Card
                style={{
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "8px",
                  padding: "16px",
                  marginBottom: "16px",
                  textAlign: "center",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <p style={{ margin: 0 }}>
                  <strong>Total Time Spent:</strong> {Math.floor(totalTimeSpent / 60)}m{" "}
                  {Math.round(totalTimeSpent % 60)}s
                </p>
              </Card>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  backgroundColor: "#222",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                {profile.attempts.map((attempt) => (
                  <li
                    key={attempt.riddleNumber}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px 16px",
                      borderBottom: "1px solid #333",
                      color: "#FFF",
                    }}
                  >
                    <span>
                      <strong>Riddle {attempt.riddleNumber}</strong>
                    </span>
                    <span>{attempt.attempts} attempts</span>
                    <span>
                      {Math.floor(attempt.timeSpent / 60)}m{" "}
                      {Math.round(attempt.timeSpent % 60)}s
                    </span>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p style={{ color: "#AAA", textAlign: "center" }}>
              No attempts recorded.
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default PublicProfile;
