import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "./Layout";
import axios from "axios";
import Progress from "./dashboard/Progress";
import Attempts from "./dashboard/Attempts";
import Leaderboard from "./dashboard/Leaderboard";

function Dashboard() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { user } = useContext(AuthContext);
  const [attempts, setAttempts] = useState([]);
  const [progress, setProgress] = useState(0);
  const [publicLink, setPublicLink] = useState("");
  const [leaderboard, setLeaderboard] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    const fetchDashboardData = async () => {
      try {
        const attemptsResponse = await axios.post(
          `${backendUrl}/api/riddle/attempts`,
          { userId: user.id },
          { withCredentials: true }
        );
        setAttempts(attemptsResponse.data.attempts || []);

        const progressResponse = await axios.get(`${backendUrl}/api/progress`, {
          withCredentials: true,
        });
        setProgress(progressResponse.data.progress || 0);

        setPublicLink(user.publicLink || "");

        const leaderboardResponse = await axios.get(
          `${backendUrl}/api/leaderboard`,
          { withCredentials: true }
        );
        setLeaderboard(leaderboardResponse.data || []);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, [user, navigate, backendUrl]);

  const handleQuest = () => navigate("/quest/start");

  const handleViewLeaderboard = () => navigate("/leaderboard");

  const handleProfile = (publicLink) => {
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/profile/${publicLink}`;
  };
  
  const handleShare = () => {
    const shareUrl = `https://twitter.com/intent/tweet?text=I've%20completed%20${progress}%25%20of%20the%20CipherQuest%20challenges!%20View%20my%20progress%20here:%20${process.env.REACT_APP_FRONTEND_URL}/profile/${publicLink}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <Layout>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#000",
          padding: "24px",
        }}
      >
        <h2
          style={{
            color: "#FFF",
            fontWeight: "bold",
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          Welcome, @{user?.username || "Guest"}!
        </h2>
        <div
          className="p-grid p-justify-center"
          style={{
            gap: "24px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          }}
        >
          <Progress
            progress={progress}
            publicLink={publicLink}
            handleShare={handleShare}
            handleQuest={handleQuest}
            handleProfile={handleProfile}
          />
          <Attempts attempts={attempts} />
          <Leaderboard
            leaderboard={leaderboard}
            handleViewLeaderboard={handleViewLeaderboard}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
