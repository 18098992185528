import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

function Progress() {
  const { user } = useContext(AuthContext);

  if (!user) {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center"
        style={{
          height: '100vh',
          backgroundColor: '#000',
          color: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: '16px',
        }}
      >
        <h1 style={{ color: '#FFF', marginBottom: '16px' }}>Welcome to Cypher.One</h1>
        <p style={{ color: '#AAA', fontSize: '16px', lineHeight: '1.5' }}>
          Log in to track your progress and share your achievements with friends.
        </p>
      </div>
    );
  }

  return (
    <div
      className="p-d-flex p-jc-center p-ai-center"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000',
        color: '#FFF',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        className="p-shadow-5"
        style={{
          maxWidth: '420px',
          width: '100%',
          backgroundColor: '#111',
          color: '#FFF',
          borderRadius: '12px',
          padding: '24px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
          textAlign: 'center',
        }}
      >
        <h2
          style={{
            color: '#FFF',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '16px',
          }}
        >
          Your Progress
        </h2>
        <ProgressBar
          value={user.progress}
          style={{
            marginBottom: '24px',
            height: '14px',
            borderRadius: '8px',
            backgroundColor: '#333',
          }}
          showValue={false}
          className="progress-bar-animated"
        />
        <p
          style={{
            fontSize: '18px',
            color: '#AAA',
            marginBottom: '32px',
          }}
        >
          <span style={{ color: '#FFF', fontWeight: 'bold' }}>
            {user.progress}%
          </span>{' '}
          of challenges completed. Keep it up!
        </p>
        <Button
          label="Share Progress"
          icon="pi pi-twitter"
          style={{
            width: '100%',
            padding: '12px 16px',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#1DA1F2',
            color: '#FFF',
            border: 'none',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
          }}
          onClick={() =>
            window.open(
              `https://twitter.com/intent/tweet?text=I've%20completed%20${user.progress}%25%20of%20riddles%20on%20Cypher.One!%20Join%20me%20at%20https://cypher.one`,
              '_blank'
            )
          }
          onMouseOver={(e) =>
            (e.target.style.backgroundColor = '#187bcd')
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = '#1DA1F2')
          }
        />
      </Card>
    </div>
  );
}

export default Progress;
