import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";

const appName = process.env.REACT_APP_APP_NAME || "Cypher.One";

function Layout({ children }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route location

  const handleLogout = async () => {
    try {
      await axios.get(`${backendUrl}/auth/logout`, { withCredentials: true });
      setUser(null);
      navigate("/login");
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div
      className="p-d-flex p-flex-column"
      style={{ minHeight: "100vh", backgroundColor: "#000", color: "#FFF" }}
    >
      {/* Header */}
      <header
        className="p-shadow-4"
        style={{
          backgroundColor: "#111",
          padding: "16px",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: "1.2rem", // Reduced font size
            fontWeight: "bold",
            whiteSpace: "nowrap", // Prevent text wrapping
          }}
        >
          {appName}
        </h1>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {user ? (
            <>
              {location.pathname !== "/dashboard" && (
                <Button
                  icon="pi pi-th-large"
                  className="p-button-rounded p-button-outlined"
                  style={{
                    width: "36px", // Reduced size
                    height: "36px",
                    padding: "0",
                    border: "1px solid rgba(255,255,255,0.4)",
                    color: "#FFF",
                  }}
                  onClick={handleNavigateToDashboard}
                  tooltip="Dashboard"
                  tooltipOptions={{ position: "bottom" }}
                />
              )}
              <Button
                icon="pi pi-sign-out"
                className="p-button-rounded p-button-outlined"
                style={{
                  width: "36px", // Reduced size
                  height: "36px",
                  padding: "0",
                  border: "1px solid rgba(255,255,255,0.4)",
                  color: "#FFF",
                }}
                onClick={handleLogout}
                tooltip="Logout"
                tooltipOptions={{ position: "bottom" }}
              />
            </>
          ) : (
            <Button
              icon="pi pi-sign-in"
              className="p-button-rounded p-button-outlined"
              style={{
                width: "36px", // Reduced size
                height: "36px",
                padding: "0",
                border: "1px solid rgba(255,255,255,0.4)",
                color: "#FFF",
              }}
              onClick={handleNavigateToLogin}
              tooltip="Login"
              tooltipOptions={{ position: "bottom" }}
            />
          )}
        </div>
      </header>

      {/* Main Content */}
      <main className="p-flex-grow-1" style={{ padding: "16px" }}>
        {children}
      </main>

      {/* Footer */}
      <footer
        className="p-shadow-4"
        style={{
          backgroundColor: "#111",
          padding: "16px",
          textAlign: "center",
        }}
      >
        <p style={{ margin: 0, fontSize: "0.9rem", color: "#888" }}>
          &copy; {new Date().getFullYear()} {appName}. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default Layout;
