import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import axios from "axios";

function QuestStart() {
  const { user } = useContext(AuthContext);
  const [riddle, setRiddle] = useState(null);
  const [totalRiddles, setTotalRiddles] = useState(null);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetchRiddleData();
    return () => {
      clearInterval(timerInterval);
      if (riddle) logEndTime();
    };
  }, []);

  useEffect(() => {
    if (riddle) {
      fetchElapsedTime();
      logStartTime();
      startTimer();
    }
  }, [riddle]);

  const fetchElapsedTime = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/riddle/timer/${riddle.id}`,
        { withCredentials: true }
      );
      setElapsedTime(response.data.elapsedTime || 0);
    } catch (error) {
      console.error("Error fetching elapsed time:", error);
    }
  };

  const fetchRiddleData = async () => {
    try {
      setLoading(true);
      const [riddleResponse, countResponse] = await Promise.all([
        axios.get(`${backendUrl}/api/riddle`, { withCredentials: true }),
        axios.get(`${backendUrl}/api/riddle/count`, { withCredentials: true }),
      ]);

      const riddleData = riddleResponse.data;
      setRiddle(riddleData);
      setTotalRiddles(countResponse.data.count);
      triggerHighlight();
    } catch (error) {
      console.error("Error fetching riddle data:", error);
      navigate("/dashboard");
    } finally {
      setLoading(false);
    }
  };

  const triggerHighlight = () => {
    setHighlight(true);
    setTimeout(() => setHighlight(false), 1000);
  };

  const logStartTime = async () => {
    try {
      await axios.post(
        `${backendUrl}/api/riddle/timer/start`,
        { userId: user.id, riddleId: riddle.id },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Error logging start time:", error);
    }
  };

  const logEndTime = async () => {
    try {
      await axios.post(
        `${backendUrl}/api/riddle/timer/end`,
        { userId: user.id, riddleId: riddle.id },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Error logging end time:", error);
    }
  };

  const startTimer = () => {
    clearInterval(timerInterval);
    const interval = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const stopTimer = async () => {
    clearInterval(timerInterval);
    await logEndTime();
  };

  const handleSubmit = async () => {
    if (!answer) return;
  
    try {
      setFeedback("");
      const response = await axios.post(
        `${backendUrl}/api/riddle/answer`,
        { answer },
        { withCredentials: true }
      );
  
      if (response.data.success) {
        setFeedback(response.data.message);
        await stopTimer();
        setTimeout(() => {
          setAnswer("");
          setFeedback(""); // Reset feedback before loading the next riddle
          fetchNextRiddle();
        }, 2000);
      } else {
        setFeedback(response.data.message || "Incorrect! Try again.");
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
      setFeedback("An error occurred. Please try again.");
    }
  };
  

  const fetchNextRiddle = async () => {
    try {
      setLoading(true);
      setFeedback(""); // Clear feedback when fetching the next riddle
      const response = await axios.get(`${backendUrl}/api/riddle`, {
        withCredentials: true,
      });
      setRiddle(response.data);
      setElapsedTime(response.data.timeSpent || 0);
      startTimer();
      triggerHighlight();
    } catch (error) {
      console.error("Error fetching next riddle:", error);
      navigate("/dashboard");
    } finally {
      setLoading(false);
    }
  };
  

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
          color: "#FFF",
        }}
      >
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      </div>
    );
  }

  if (!riddle) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
          color: "#FFF",
        }}
      >
        <p>No riddle available. Please return to the dashboard.</p>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#000",
        color: "#FFF",
        padding: "24px",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "24px" }}>
        CipherQuest Challenge ({riddle.riddleNumber || 1} of{" "}
        {totalRiddles || "N/A"})
      </h2>
      <h3 style={{ color: "#AAA", marginBottom: "16px", textAlign: "center" }}>
        You have{" "}
        <strong>
          {parseInt(process.env.REACT_APP_MAX_ATTEMPTS, 10) - (riddle.attempts || 0)}
        </strong>{" "}
        attempts remaining. Time spent:{" "}
        <strong>
          {Math.floor(elapsedTime / 60)}m {elapsedTime % 60}s
        </strong>
      </h3>

      <Divider />

      <Card
        className={`p-shadow-5 ${highlight ? "highlight" : ""}`}
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          backgroundColor: "#111",
          color: "#FFF",
          borderRadius: "8px",
          padding: "24px",
          textAlign: "center",
          transition: "background-color 0.5s ease",
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: riddle.content }}
          style={{ color: "#AAA", marginBottom: "40px", wordWrap: "break-word" }}
        />

        <InputText
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Enter your answer"
          style={{
            width: "100%",
            padding: "12px",
            marginBottom: "16px",
            borderRadius: "8px",
            border: "1px solid rgba(255,255,255,0.4)",
            color: "#FFF",
            backgroundColor: "#222",
          }}
        />

        <Button
          label="Submit Answer"
          icon="pi pi-check"
          style={{
            width: "100%",
            padding: "12px",
            backgroundColor: "#1DA1F2",
            color: "#FFF",
            fontWeight: "bold",
            borderRadius: "8px",
            marginBottom: "16px",
          }}
          onClick={handleSubmit}
        />

        {feedback && (
          <p
            style={{
              marginTop: "16px",
              color: feedback.includes("Correct") ? "#4CAF50" : "#E57373",
            }}
          >
            {feedback}
          </p>
        )}

        <Button
          label="Back to Dashboard"
          icon="pi pi-arrow-left"
          className="p-button-outlined"
          style={{
            width: "100%",
            padding: "12px",
            color: "#FFF",
            fontWeight: "bold",
            borderRadius: "8px",
            border: "1px solid rgba(255,255,255,0.4)",
          }}
          onClick={() => navigate("/dashboard")}
        />
      </Card>
    </div>
  );
}

export default QuestStart;
