import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18 root API
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import './index.css'; // Global styles

// Get the root DOM element
const rootElement = document.getElementById('root');

if (rootElement) {
  // Create a React root and render the app
  createRoot(rootElement).render(
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
}
