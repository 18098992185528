import React from "react";
import Layout from "./Layout";

function PrivacyPolicy() {
  return (
    <Layout>
      <div style={{ color: "#FFF", padding: "16px", fontSize: "0.9rem", lineHeight: "1.6" }}>
        <h1 style={{ fontSize: "1.8rem", marginBottom: "16px" }}>Privacy Policy</h1>
        <p>
          At <strong>C1pher.Quest</strong>, we prioritize your privacy and are committed to being transparent about how we handle your information. This Privacy Policy outlines what data we collect, how it is used, and your rights as a user.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>1. Information We Collect</h2>
        <p>We collect the following types of information:</p>
        <ul style={{ marginLeft: "16px", listStyleType: "circle" }}>
          <li><strong>Personal Information:</strong> Information you provide when registering, such as your username, email address, and other contact details.</li>
          <li><strong>Activity Data:</strong> Information about your progress, attempts, and interactions on the platform.</li>
          <li><strong>Technical Data:</strong> Your IP address, browser type, device information, and access times, collected to improve platform functionality.</li>
        </ul>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>2. How We Use Your Information</h2>
        <p>We use your data to:</p>
        <ul style={{ marginLeft: "16px", listStyleType: "circle" }}>
          <li>Provide and enhance the platform's functionality and user experience.</li>
          <li>Communicate updates, respond to inquiries, and provide support.</li>
          <li>Analyze trends and user behavior to improve puzzles and features.</li>
          <li>Enforce our <a href="/terms" style={{ color: "#00ADEF" }}>Terms and Conditions</a>.</li>
        </ul>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>3. Data Retention</h2>
        <p>
          - Your data is retained for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required by law.
          <br />
          - You may request deletion of your data by contacting us (see Section 7).
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>4. Data Security</h2>
        <p>
          We use industry-standard measures to protect your data, including encryption and secure storage. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>5. Sharing Your Information</h2>
        <p>We do not sell your personal information. However, we may share your data with:</p>
        <ul style={{ marginLeft: "16px", listStyleType: "circle" }}>
          <li><strong>Trusted Service Providers:</strong> To support platform operations, such as hosting and analytics.</li>
          <li><strong>Legal Compliance:</strong> If required to comply with legal obligations or protect our rights and users.</li>
        </ul>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>6. Your Rights</h2>
        <p>As a user, you have the following rights:</p>
        <ul style={{ marginLeft: "16px", listStyleType: "circle" }}>
          <li><strong>Access:</strong> Request access to your personal data.</li>
          <li><strong>Correction:</strong> Update or correct your data if it is inaccurate or incomplete.</li>
          <li><strong>Deletion:</strong> Request deletion of your personal data.</li>
          <li><strong>Opt-Out:</strong> Choose not to receive non-essential communications.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:support@c1pher.quest" style={{ color: "#00ADEF" }}>
            support@c1pher.quest
          </a>.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>7. Cookies and Tracking</h2>
        <p>
          - We use cookies and similar tracking technologies to enhance your experience. These include session cookies, analytical cookies, and functionality cookies.
          <br />
          - You can manage or disable cookies through your browser settings, though this may impact certain features of the platform.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>8. Updates to This Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. You will be notified of significant changes through the platform or via email.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>9. Governing Law</h2>
        <p>
          This Privacy Policy is governed by the laws of your jurisdiction. Any disputes shall be resolved in the appropriate courts.
        </p>

        <h2 style={{ fontSize: "1.4rem", marginTop: "24px", marginBottom: "12px" }}>10. Contact Us</h2>
        <p>
          For questions or concerns regarding your privacy, please contact us at:
          <br />
          <a href="mailto:support@c1pher.quest" style={{ color: "#00ADEF" }}>
            support@c1pher.quest
          </a>
        </p>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
